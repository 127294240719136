import { ArBrandModals, ArButton, ButtonTypes } from "@anarock/ar-common-react";
import { ArRadioOptionsModalProps } from "@anarock/ar-common-react/dist/ui-components/ar-brand-modals/index.interface";
import { IClientPOC, IPOCModule, useGetClientPOCListQuery } from "app/services/invoices";
import { isNullOrUndefiend } from "app/utils/helperFunctions";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React, { useEffect, useState } from "react";

import { getClientPOCModalOptions, getClientPOCObjFromArray } from "../IRFContactDetails/partials";
import { AddClientPOCModal } from "./partials";

interface ClientPOCListProps extends Omit<ArRadioOptionsModalProps, "options" | "onSubmit"> {
  clientId: string | undefined;
  clientType: IPOCModule;
  onSubmit: (obj?: IClientPOC) => void;
}

const ClientPOCListModal: React.FC<ClientPOCListProps> = (props) => {
  const [clientPOCModalOpen, setClientPOCModalOpen] = useState(false);
  const [open, setOpen] = useState(props.open || false);

  const { data: clientPOCDetails } = useGetClientPOCListQuery(
    { clientType: props?.clientType as string, clientId: props?.clientId as string },
    {
      refetchOnMountOrArgChange: true,
      skip: isNullOrUndefiend(props?.clientId),
    }
  );

  const handleSubmit = (val: string) => {
    const obj = getClientPOCObjFromArray(clientPOCDetails?.data?.pocs, val);
    props?.onSubmit(obj);
  };

  useEffect(() => {
    setOpen(props.open || false);
    setClientPOCModalOpen(false);
  }, [props.open]);
  const toggleClientPOCModal = () => {
    setClientPOCModalOpen(!clientPOCModalOpen);
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <ArBrandModals.ArRadioOptionsModal
        footerButtons={[
          <ArButton key="1" type={ButtonTypes.Secondary} size="large" onClick={toggleClientPOCModal}>
            {STRING_CONSTANTS.ADD_NEW_POC}
          </ArButton>,
        ]}
        options={getClientPOCModalOptions(clientPOCDetails?.data?.pocs || [])}
        {...props}
        onSubmit={(val) => handleSubmit(val as string)}
        open={open}
      />
      <AddClientPOCModal
        title={STRING_CONSTANTS.ADD_NEW_CLIENT_POC}
        open={clientPOCModalOpen}
        onCancel={toggleClientPOCModal}
        clientId={props?.clientId}
        clientType={props?.clientType}
      />
    </React.Fragment>
  );
};

export default ClientPOCListModal;
