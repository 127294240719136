import { ArInputType, inputType, nodeType } from "@anarock/ar-common-react";
import { IClientGST, IClientPOC } from "app/services/invoices";
import { requiredRulesOnChange } from "components/requirmentFormUi/rules";
import { CONSULTING_PROFIT_CENTER } from "constants/index";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { getProfitCenterModalOptions } from "features/manageRequirement/create-requirement/partials";
import React from "react";

export const getClientGSTModalOptions = (itemList: IClientGST[] | undefined) => {
  if (!itemList) {
    return [];
  }
  const list = itemList?.map(({ gst, address }) => ({
    label: (
      <>
        <p>{gst?.gstNumber}</p>
        <p className="sub-hero-text-regular-medium pt-4">{`${address?.address}, ${address?.pincode}, ${address?.cityName}, ${address?.stateName}`}</p>
      </>
    ),
    value: `${address?.address}, ${address?.pincode}, ${address?.cityName}, ${address?.stateName}, ${gst?.gstNumber}`,
  }));
  return list;
};

export const getClientPOCModalOptions = (itemList: IClientPOC[] | undefined) => {
  if (!itemList) {
    return [];
  }
  const list = itemList?.map(({ name, email, phoneNumber }) => ({
    label: (
      <>
        <p>{name}</p>
        <p className="sub-hero-text-regular-medium pt-4">{`${phoneNumber?.number} || ${email}`}</p>
      </>
    ),
    value: `${phoneNumber?.number} || ${email} || ${name}`,
  }));
  return list;
};

export const getClientPOCObjFromArray = (itemList: IClientPOC[] | undefined, searchStr: string) => {
  const obj = itemList?.find(
    ({ phoneNumber, email, name }) => searchStr === `${phoneNumber?.number} || ${email} || ${name}`
  );
  return obj;
};

export const getClientGSTObjFromArray = (itemList: IClientGST[] | undefined, searchStr: string) => {
  const obj = itemList?.find(
    ({ address, gst }) =>
      searchStr ===
      `${address?.address}, ${address?.pincode}, ${address?.cityName}, ${address?.stateName}, ${gst?.gstNumber}`
  );
  return obj;
};

export const getAddressOptionsFromArray = (gstDetails: IClientGST[]) => {
  const list = gstDetails.map(({ address, gst }) => ({
    label: `${address?.address}, ${address?.pincode}, ${address?.cityName}, ${address?.stateName}`,
    value: `${address?.address}, ${address?.pincode}, ${address?.cityName}, ${address?.stateName}, ${gst?.gstNumber}`,
  }));
  return list;
};

export const getProfitCenterNode = (profitCenterList?: string[], isLeasing?: boolean, profitCenters?: string[]) => {
  return {
    type: nodeType.input,
    elementData: {
      inputType: inputType.selectBox,
      formItemProps: {
        name: FORM_FIELDS.PROFIT_CENTER,
        rules: requiredRulesOnChange,
      },
      inputProps: {
        type: ArInputType.text,
        label: FORM_FIELDS.PROFIT_CENTER_LABEL,
        required: true,
        options: isLeasing
          ? getProfitCenterModalOptions(profitCenterList || [])
          : getProfitCenterModalOptions(
              profitCenters?.includes(CONSULTING_PROFIT_CENTER) ? [CONSULTING_PROFIT_CENTER] : []
            ),
      },
    },
  };
};
