import { IWIPCommisionDetails } from "app/services/wip";
import { IReferenceColor } from "components/referenceColors/index.interface";

export const WIPCommisionArray: IWIPCommisionDetails[] = [
  { type: "Origination", userId: "", userName: "", weightage: "" },
  { type: "Follow up/ Negotiation", userId: "", userName: "", weightage: "" },
  { type: "Documentation/ Closure", userId: "", userName: "", weightage: "" },
  { type: "Collection", userId: "", userName: "", weightage: "" },
];

export const MonthlyBrokerageValues = {
  current: 0,
  target: 0,
  okay: 0,
  query: 0,
  tentative: 0,
};

export enum WIP_APPROVAL_SUB_STATUS {
  APPROVED = "APPROVED", // WIP Approved
  APPROVED_CHANGES = "APPROVED_CHANGES", //Approved WIP but changes are also submitted
  PENDING = "PENDING", // First WIP pending (No WIP Approved yet)
  PENDING_NO_SUBMISSION = "PENDING_NO_SUBMISSION", // Changes done in approved wip, but not submitted
  PENDING_CHANGES = "PENDING_CHANGES", // Changes done in approved wip, chnaged submitted
  DECLINED = "DECLINED", //new WIP changes declined
  DECLINED_NO_SUBMISSION = "DECLINED_NO_SUBMISSION", //Declined WIP and not submit (this case should not come )
  DECLINED_CHANGES = "DECLINED_CHANGES", // proposed changes to prev approved wip are declined
  APPROVED_NO_SUBMISSION = "APPROVED_NO_SUBMISSION", // changes are made in approved WIP but not submitted
  DRAFT = "DRAFT",
}

export enum INVOICE_APPROVED_TAB {
  PENDING = "PENDING",
  DECLINED = "DECLINED",
  APPROVED_GENERATED = "APPROVED_GENERATED",
  PENDING_INVOICE = "PENDING_INVOICE",
  DRAFT = "DRAFT",
}

export enum WIP_APPROVAL_STATUS {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  DECLINED = "DECLINED",
}

export enum SHEET_MATCHING_STATUS {
  MATCHED = "matched",
  MISMATCHED = "mismatched",
  ERROR = "error",
  PREVIOUSLY_SYNCED = "previouslySynced",
}

export enum MARKET_TYPE_TAB {
  MALL = "MALL",
  HIGH_STREET = "HIGHSTREET",
}

export const SAMPLE_REVENUE_SHEET_URL =
  "https://docs.google.com/spreadsheets/d/1i7z3p61hSw3cPGvhyCiHKtSHeSHNoEhoPkAem1SdyGU";

export const SAMPLE_INVOICE_SHEET_URL =
  "https://docs.google.com/spreadsheets/d/1QplXcAAlxVdsrbOIpPKvyh8g56xVTR_8w3ThSL4HAG0";

export const SAMPLE_COLLECTION_SHEET_URL =
  "https://docs.google.com/spreadsheets/d/1PoZZNryJP9qZJq8_Z5RKVsGqcNn7zNBCzxPx4gvhSXw";

export const WIP_TABLE_COLOR_REFERENCE: IReferenceColor[] = [
  { name: "Projection", color: "primary-100" },
  { name: "Billed", color: "emerald-150" },
  { name: "Query", color: "yellow-200" },
  { name: "Collected", color: "emerald-400" },
  { name: "Dropped", color: "red-400" },
];

export const COLLECTION_TABLE_COLOR_REFERENCE: IReferenceColor[] = [
  { name: "Billed", color: "emerald-150" },
  { name: "Collected", color: "emerald-400" },
  { name: "Issue/Legal", color: "yellow-200" },
  { name: "Write off", color: "red-400" },
];

export enum IRF_STATUS_VALUES {
  DRAFT = "DRAFT",
  LEAD_DECLINED = "LEAD_DECLINED",
  LEAD_PENDING = "LEAD_PENDING",
  FINANCE_PENDING = "FINANCE_PENDING",
  FINANCE_DECLINED = "FINANCE_DECLINED",
  FINANCE_APPROVED = "FINANCE_APPROVED",
  INVOICE_GENERATED = "INVOICE_GENERATED",
  INVOICE_PENDING = "INVOICE_PENDING",
  DOCUMENT_REQUIRED_FOR_REV_RECOG = "DOCUMENT_REQUIRED_FOR_REV_RECOG",
  REVENUE_RECOGNITION_PENDING = "REVENUE_RECOGNITION_PENDING",
  INVOICED = "INVOICED",
}

export const IRF_STATUS_IN_TABLE = {
  FINANCE_PENDING: { text: "Finance Pending", color: "yellow-200" },
  LEAD_PENDING: { text: "Lead Pending", color: "yellow-200" },
  FINANCE_DECLINED: { text: "Finance Declined", color: "red-400" },
  LEAD_DECLINED: { text: "Lead Declined", color: "red-400" },
  FINANCE_APPROVED: { text: "Finance Approved", color: "green-400" },
  INVOICE_GENERATED: { text: "Invoice Generated", color: "green-400" },
  DRAFT: { text: "Draft Request", color: "primary-400" },
  INVOICE_PENDING: { text: "Invoice Pending", color: "yellow-200" },
  DOCUMENT_REQUIRED_FOR_REV_RECOG: { text: "Document required for revenue recognition", color: "yellow-200" },
  REVENUE_RECOGNITION_PENDING: { text: "Revenue recognition pending", color: "yellow-200" },
  INVOICED: { text: "Invoiced", color: "green-400" },
};

export enum PAYMENT_CLAIM_STATUS {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  PENDING = "PENDING",
}

export const PAYMENT_CLAIM_STATUS_IN_TABLE = {
  PENDING: { text: "Request Pending", color: "yellow-200" },
  DECLINED: { text: "Request Declined", color: "red-400" },
  APPROVED: { text: "Payment Approved", color: "green-400" },
};
export const WIP_OPTIONS = [
  { label: "Consulting", value: "Consulting" },
  { label: "Leasing", value: "Leasing" },
];
export const WIP_LEASING_DEAL_OPTIONS = [
  { label: "High street", value: "High street" },
  { label: "Mall", value: "Mall" },
];

export const BILLING_CLIENT_OPTIONS = [
  { label: "Brand", value: "Brand" },
  { label: "Landlord", value: "Landlord" },
  { label: "Landlord & Brand", value: "Landlord & Brand" },
];

export const IRF_TYPE_OPTIONS = [
  { label: "Revenue and Invoice", value: "Revenue and Invoice" },
  { label: "Only Invoice (Revenue recognition later)", value: "Only Invoice" },
];

export const SERVICE_FEES_BASE_OPTIONS_ENUM = {
  1: "Minimum Guarantee Rent p.m. * No of days/30",
  2: "Minimum Guarantee Rent p.m. * No of months",
  3: "%age or multiple of Security deposit amount",
  4: "Lump sum",
};

//keeping same ENUMS as backend as rendering logic will be based on this
export const paymentClaimTypeEnum = {
  PAYMENT_TDS: "Payment & TDS",
  PAYMENT: "Payment Only",
  TDS: "TDS Only",
};
export const paymentTypeEnum = {
  CHEQUE: "Cheque",
  NEFT_RTGS: "NEFT/RTGS",
};

export const CONSULTING_PROFIT_CENTER = "A01HQRETAILCONSULT";

export enum KYC_STATUS {
  APPROVED = "APPROVED",
  CLIENT_PENDING = "CLIENT_PENDING",
  AGENT_PENDING = "AGENT_PENDING",
  DECLINED = "DECLINED",
  DEFAULT = "DEFAULT",
}

export const KYC_STATUS_IN_TABLE = {
  APPROVED: { text: "Approved", color: "green-400" },
  DEFAULT: { text: "KYC not initiated", color: "yellow-200" },
  CLIENT_PENDING: { text: "Pending on client", color: "yellow-200" },
  AGENT_PENDING: { text: "Agent approval pending", color: "yellow-200" },
  DECLINED: { text: "Declined KYC", color: "red-400" },
};
