export enum STRING_CONSTANTS {
  RECENT_UPDATE = "Recent Update",
  HOME = "Home",
  NO_IMAGE = "No image",
  EDIT = "Edit",
  SEE_MORE = "See more",
  SEE_LESS = "See less",
  SHOP_HISTORY = "Shop history",
  RECENT_HISTORY = "Recent history",
  ASK_TO_UPDATE = "Ask to update",
  ADD_TO_OPTIONS_LIST = "Add shop to brand's option list in ",
  SHOP_ALREADY_IN_OPTIONS_LIST = "Shop already in these option lists",
  NEWLY_ADDED_SHOPS = "Newly added shops",
  PREVIOUSLY_ADDED_SHOPS = "Previously added",
  REQUIREMENT_CREATED_SUCCESSFULLY = "Requirement created successfully",
  REQUIREMENT_EDITTED_SUCCESSFULLY = "Requirement editted successfully",
  ADD_REQUIREMENTS = "Add Requirements",
  EDIT_REQUIREMENT = "Edit Requirement",
  SAVE_REQUIREMENT_CHANGES = "Save Requirement Changes",
  CREATE_REQUIREMENT = "Create Requirement",
  DEACTIVATE_REQUIREMENT = "Deactivate Requirement",
  SOMETHING_WENT_WRONG = "Something went wrong",
  MOVED_THIS_SHOP_TO = "moved this shop to",
  OF = "of",
  YOU_CAN_ADD_ONLY_5_ALTERNATE_NUMBERS = "You can add only 5 alternate numbers",
  ADD_ALTERNATE = "Add Alternate",
  REMOVE = "Remove",
  BRAND_POC_DETAILS = "Brand POC Details",
  ENTER_AT_LEAST = "Please enter at least",
  ENTER_UPTO = "Please enter upto",
  CHARACTERS = "characters.",
  ENTER_VALID_WHOLE_NUMBER = "Please enter a valid whole number.",
  ENTER_VALID_EMAIL = "Please enter a valid email ID.",
  MIN_CARPET_AREA_GERATER_THAN_MAX_CARPET_AREA = "Max carpet area should be greater than min carpet area.",
  FETCHING_INDUSTRIES = "Please wait while loading industries.",
  PROVIDE_BRAND_REQUIREMENT_ID = "Please provide brand requirement Id.",
  ALTERNATE_PHONE_NUMBER = "Alternate Phone Number",
  ALTERNATE_PHONE_NUMBERS = "Alternate Phone Numbers",
  UPLOAD_REQUIREMENT = "Upload Requirement",
  LOCALITY = "Locality",
  OTHER_INFO = "Other Info",
  SUBMIT_BUTTON = "submitButtom",
  SUBMIT = "submit",
  REQUIREMENT_DETAILS = "Requirement Details",
  REQUEST_SENT_SUCCESSFULLY = "Request sent successfully",
  SHARE_OPTIONS = "Generate Brochure",
  LOADING_PLEASE_WAIT = "Loading, Please wait",
  FAILED_TO_GENERATE_BROCHURE = "Failed to generate brochure",
  NO_MATCHING_REQUIREMENTS_FOUND = "No matching requirements found",
  NO_DRAFT_SHOPS_FOUND = "No draft shops found in",
  FRONT_VIEW = "Front View",
  LEFT_VIEW = "Left View",
  RIGHT_VIEW = "Right View",
  BACK_VIEW = "Back View",
  VICINITY_VIEW = "Vicinity View",
  OPTIONAL = "Optional",
  DOOR_HEIGHT = "Height",
  BRAND_MANAGER = "Brand Manager",
  CORNER_PROPERTY = "Corner Property",
  LEASE_TERM = "Lease Term",
  LAST_TENANT = "Last Tenant",
  AVAILABLE_FROM = "Available From",
  CURRENT_TENANT = "Current Tenant",
  NOTES = "Notes",
  SHOP_VACANT_FROM = "Shop Vacant From",
  VIEW_DETAILS = "View Details",
  ADD_BRAND = "Add Brand",
  BROWSE_FILE = "Browse File",
  ENTER_BRAND_NAME = "Please enter the brand name and reupload the image.",
  LOGO_UPLOADED_SUCCESSFULLY = "Logo uploaded successfully",
  BRAND_ADDED_SUCCESSFULLY = "Brand added successfully",
  BRAND_EDITED_SUCCESSFULLY = "Brand edited successfully",
  LL_ADDED_SUCCESSFULLY = "LandLord added successfully",
  LL_UPDATE_SUCCESSFULLY = "LandLord updated successfully",
  ADD_LOGO = "Add Logo",
  DRAG_FILES_HERE = "Select or drag to attach documents.",
  BRAND_NAME_REQUIRED = "Brand name is required to upload logo.",
  PROPERTIES_REQUESTED = "Surveyor requested to add properties.",
  FETCHING_MATCHING_PROPERTIES = "Please wait while we match shops for you.",
  FETCHING_OPTIONS_LIST = "Please wait while we fetch your option list.",
  FETCHING_REQUIREMENTS = "Please wait, fetching requirements.",
  FETCHING_SHOPS = "Please wait, fetching shops.",
  NO_SHOPS_IN_LEAD_GROUP = "No shops in this lead group.",
  BACK_TO_MATCHES = "Back to matches.",
  GENERATING_BROCHURE = "Generating brochure.",
  OTHER_SHOPS_REJECTED = "otherShopsRejected",
  MARK_OTHER_SHOPS = "Mark other shops as",
  REJECTED = "rejected.",
  SELECT_ATLEAST_ONE_SHOP = "Please select atleast one property to generate brochure.",
  DOWNLOAD_BROCHURE = "Download Brochure",
  SHARE_BROCHURE = "Share Brochure",
  BROCHURE_GENERATION_IN_PROGRESS = "Brochure generation in progress.",
  REQUIREMENT_DEACTIVATED = "Requirement deactivated successfully",
  SHOPS_IN = "Shops in",
  VIEW_SHOPS = "View Shops",
  HIDE_SHOPS = "Hide Shops",
  SURVEYOR = "Surveyor",
  SELF_UPDATE = "Self Update Status",
  CANCEL = "Cancel",
  UPDATE_STATUS = "Update Status",
  NO_MATCHING_PROPERTY = "No matching properties in",
  NO_NEARBY_PROPERTY = "No nearby properties found",
  REQUEST_PROPERTIES = "Request Properties",
  GOOD_MORNING = "Good Morning",
  YOUR_PERFORMANCE = "Here is your Performance",
  TARGET_IN_Q = "Target in Q",
  LOI_SIGNS = "LOI Signs",
  MOST_INTERESTED_REQUIREMENTS = "Most interacted requirements",
  SHOWING = "Showing",
  REQUIREMENTS = "Requirements",
  NO_REQUIREMENTS_FOUND = "No requirements found",
  UPDATED = "Updated",
  CREATE_NEW_LOCALITY = "Create New Locality",
  STOP_CREATING = "Stop Creating",
  LOCALITY_INFORMATION = "Locality Information",
  LOCALITY_PROPERTIES = "Here you can change Locality properties...",
  LOCALITY_NAME = "Locality name",
  LOCALITY_NAME_PLACEHOLDER = "Locality name will come here",
  LOCALITY_ERROR_MESSAGE = "Name already used! Please use different name",
  DISCARD_CHANGES = "Discard Changes",
  SAVE_CHANGES = "Save Changes",
  UPDATE_CHANGES = "Update Changes",
  ACCESS_ERROR_MESSAGE = "You don’t have access to this URL. Please get in touch with the tech team.",
  MAP_VIEW = "Map View",
  LIST_VIEW = "List View",
  MIDDLE = "middle",
  SEARCH_SHOP = "Search shop",
  SEARCH_SHOP_PLACEHOLDER = "Search shop by it’s Parameters",
  SEARCH_TABLE = "Search Table",
  SEARCH_GOOGLE_MAP = "Search Google Map",
  VIEW_ALL = "View All",
  MATCHING = "Matching",
  RESENT_SUCCESS = "Resent link successfully",
  PASSWORD_REQUIRED = "Password is required",
  LOGIN_GLAD = "Glad to see you!",
  SIGN_IN_CONTINUE = "Sign in to continue",
  ENTER_PASSWORD = "Enter your password",
  FORGOT_PASSWORD = "Forgot password",
  CONFIRM = "Confirm",
  LOGIN = "Login",
  BACK_TO_LOGIN = "Back to log in",
  RESET_SENT = "Reset Link Sent Successfully!",
  RETURN_TO_LOGIN = "Return to log in",
  RESEND_LINK_BTN = "Resend link",
  DIDNT_RECIEVE = "Didn’t receive it?",
  LINK_SENT_1 = "we’ve sent link to",
  LINK_SENT_2 = "with password reset instructions.",
  RESET_PASSWORD = "Reset your password",
  NO_FEAR = "Fear not! we will email you instructions to reset your password",
  ALMOST_DONE_RESET = "Almost done. enter your new password, and you’re good to go",
  CONGRATS = "Congratulations!",
  RESET_SUCCESS_1 = "Your password has been changed.",
  RESET_SUCCESS_2 = "You can now log in with new password.",
  CHANGE_PASSWORD = "Change password",
  PASSWORD_NO_MATCH = "Password does not match",
  MIN_AREA = "Min Area",
  MAX_AREA = "Max Area",
  SELECT_CARPET_AREA = "Select carpet area Range (sq.ft)",
  TO = "to",
  CREATE_WIP = "Create WIP",
  EDIT_WIP = "Edit WIP",
  SUMMARY = "Summary",
  WIP = "WIP",
  ADD = "Add",
  SELECT_BRAND_FOR_OPTION_LIST = "Select Brands to add this shop in their option list",
  CITY = "City",
  CONTACT_DETAIL = "Contact Detail",
  ADD_CONTACT_BUTTON = "Add more Contacts",
  WIP_SUMMARY_CONTACT = "1-Contact Details (Shop’s Owner/Landlord)",
  WIP_SUMMARY_COMMERCIAL = "2-Commercial & Projection Details",
  WIP_SUMMARY_BROKERAGE = "3-Monthly Brokerage Projection",
  WIP_CURRENT_STATUS = "4-Current WIP Status",
  WIP_BILLING_MILESTONE = "5-Billing Milestones & its completion dates",
  WIP_CHOOSE_SHOP = "Choose 1 shop to create WIP",
  START_CREATING = "Start Creating",
  DONT_CREATE = "Don’t create!",
  NO_SHOPS_AVAILABLE = "No shops available",
  WIP_SUMMARY = "Summary",
  WIP_DOCUMENTS = "Documents",
  UPDATE_WIP_STATUS = "Update WIP Status",
  CHANGE = "Change",
  BRAND = "Brand",
  DEAL_LOCATION = "Deal city location",
  OFFICE_LOCATION = "Office city location",
  CHOOSE_BILLING_MILESTONE = "Choose Billing Milestones",
  UPDATE_BILLING_MILESTONE = "Update Billing Milestones",
  CHOOSE_WIP_STATUS = "Choose WIP Status ",
  CLICK_TO_UPLOAD = "Click to upload",
  ONLY_5_POC = "You can add only 5 Contacts",
  MARKET_MAP = "Market Map",
  NEXT_STEP = "Next Step",
  NEXT = "Next",
  SAVE_PROGRESS = "Save Progress",
  COMMERCIAL_DETAILS_STEP = "Step:2 Commercial & Brokerage Details",
  CONTACT_DETAILS_STEP = "Step:1 Contact Details (Shop’s Owner / Landlord )",
  BROKERAGE_PROJECTION_STEP = "Step:3 Monthly Brokerage Projection",
  PREVIOUS_STEP = "Previous Step",
  RENT = "Rent",
  CONTACT = "Contact",
  COMMERCIAL_BROKERAGE = "Commercial & brokerage",
  BROKERAGE_PROJECTION = "Brokerage Projection",
  FY_PROJECTION_1 = "FY Brokerage Details (Total Brokerage-",
  FY_PROJECTION_2 = ")",
  ADD_FY = "Add more Years",
  ADD_MONTH = "Add more Months",
  ADD_AGENT = "Add More Employees For Commission",
  DAYS = "Days",
  MONTH = "Month",
  TOTAL_REVENUE = "Total Revenue",
  LL_REVENUE = "LL Revenue",
  LAND_LORD_REVENUE = "Landlord Revenue",
  BRAND_REVENUE = "Brand Revenue",
  ONLY_5_FY = "You can add only 5 Financial years.",
  ONLY_5_MONTHS = "You can add only 5 Months in a Finnacial year.",
  MONTHS_LIMIT_5 = "You can add only 5 Months.",
  INVOICE_LIMIT_5 = "You can add only 5 Invoices.",
  AGENTS_LIMIT_5 = "You can add only 5 Agents.",
  HIDE_PROJECTION = "Hide Projection",
  SHOW_PROJECTION = "Show Projection",
  PREVIEW_WIP_BTN = "Preview WIP",
  SUBMIT_WIP_BTN = "Submit WIP",
  SELECT_ATLEAST_ONE_SHOP_FOR_WIP = "Please select atleast one shop",
  REVENUE_FROM_LL = "Revenue from LL",
  REVENUE_FROM_BRAND = "Revenue from Brand",
  TOTAL_LL_BROKERAGE = "Total LL Brokerage",
  TOTAL_BRAND_BROKERAGE = "Total Brand Brokerage",
  TOTAL_BROKERAGE = "Total Brokerage ",
  PROJECTION = "Projection",
  SAVE = "Save",
  COMMENT = "Comment",
  WIP_VALIDATIONS_FAILED = "Form validations failed please check.",
  GO_BACK_BTN = "Go Back",
  OK_UNDERSTOOD = "Ok! Understood",
  REVIVING_DROP_DEAL = "You are attempting to revive the WIP by changing its status; however,",
  REVIVING_DROP_DEAL_STEP_1 = "Only the 'Negotiation' WIP status is allowed during revival",
  REVIVING_DROP_DEAL_STEP_2 = "Brokerage and projections will be the same as they were during dropping the deal",
  SURE_YOU_WANT_TO_REVIVE = "Are you sure you want to revive?",
  NEGOTIATION_WIP_STATUS_ALLOWED = "However, only the 'Negotiation' WIP status is allowed for this transition.",
  STATUS_FROM_DROP_DEAL = "You are attempting to change the WIP status from 'Dropped Deal' to",
  YES_REVIVE = "Yes! Revive",
  REVIVING_DROPPED_DEAL = "Revive Dropped Deal?",
  CHANGING_WIP_STATUS = "Changing WIP Status?",
  WIP_STATUS_AND_DOCUMENTS = "WIP Status & their Documents",
  DELETE_DOCUMENT_AND_UPDATE_STATUS = "Delete Document & Update WIP Status",
  CONFIRM_DELETE_DOCUMENT = "Are you sure you want to delete this document? Deleting it will require you to update the WIP status as well",
  CURRENT_WIP_STATUS = "Current WIP Status",
  CONFIRM_DOCUMENT_DELETION = "Confirm Document Deletion?",
  DROP_CONFIRMATION = "Drop Confirmation",
  DROP_CONFIRMATION_MESSAGE = "You are about to drop the deal, you can not edit WIP once dropped unless it is revived. Are you sure you want to drop this deal? ",
  DROP_CONFIRMATION_MESSAGE_2 = "If you have one more deal in the Negotiation state, you can make a new WIP even after dropping this deal.",
  YES_DROP = "Yes! Drop",
  DONT_DROP = "Don’t Drop",
  DEAL_DROPPED_SUCCESSFULLY = "Deal Dropped Successfully",
  DEAL_DROPPED_SUCCESS_MESSAGE = "It's alright if one deal didn't work out. You still have another deal in negotiation. You can now start making new WIP for this available deal",
  UPDATE_WIP = "Update WIP",
  VIEW_WIP = "View WIP",
  WIP_STATUS_UPDATE_ALERT = "WIP Status Update Alert",
  WIP_STATUS_UPDATE_ALERT_MESSAGE = "You are trying to change dropped deal WIP Status, multiple WIPs Can not be created for One brand at one location",
  SUBMIT_WIP_TEXT = "Your WIP will be Shared to business.they Can view and edit it. Are you sure you want to submit?",
  SUBMIT_CONFIMATION = "Submit Confirmation",
  YES_SUBMIT = "Yes! Submit",
  NO_SAVE_AS_DRAFT = "No! Save as draft",
  CHANGE_BROKERAGE_DETAILS_TITLE = "Changing brokerage details",
  YES_GOTO_STEP_3 = "Yes! Go to step 3",
  CHANGE_BROKERAGE_TEXT = "Changing brokerage details will require changing monthly brokerage projection in step 3. Are you sure you want to change?",
  ALL = "all",
  LAST_EDITED = "Last edited",
  CREATED = "Created",
  SURVEYOR_ANALYTICS = "Surveyor Analytics",
  PERFORMANCE_TABLE = "Performance table",
  ALL_RESULTS = "All Results",
  PERFORMANCE_CARD = "Performance Card",
  REGIONS = "Regions",
  ANALYTICS = "Analytics",
  TOTAL_SHOPS_CREATED = "Total Shops Created",
  UPDATED_SHOPS = "Updated Shops",
  EXPIRED_SHOPS = "Expired Shops",
  IN_SEVEN_DAYS = "in 7 days:",
  CREATED_SHOPS = "Created Shops",
  MONTHLY_AVG_SHOPS = "Monthly Avg Shops",
  NUMBER_OF_SHOPS = "No. of Shops",
  HOUR_AGO = "hour ago",
  HOURS_AGO = "hours ago",
  DAYS_AGO = "days ago",
  ZERO_HOUR_AGO = "0 hour ago",
  ON = "on",
  START_DATE = "Start Date",
  END_DATE = "End Date",
  START_DATE_AFTER_END_DATE_ERROR = "Start date should be less than end date.",
  END_DATE_BEFORE_START_DATE_ERROR = "End date should be greater than start date.",
  LOI_SIGNED = "LOI signed Shops",
  AGENT_ANALYTICS = "Agent Analytics",
  REQ_CREATED_THIS_MONTH = "Req Created This Month",
  REQ_GREATER_THAN_4_MATCHING = "Req With >4 Matching Shops",
  REQ_GREATER_THAN_2_OPTIONS = "Req With >2 Shops In Option List",
  LOI_SIGNED_REQ = "LOI Signed Req",
  SHOWING_RESULTS = "Showing Results",
  TOTAL_REQ_CREATED = "Total Req Created",
  AGENTS = "Agents",
  VIEW = "View",
  SELECT = "Select",
  MORE_FILTERS = "More Filters",
  SQ_FT = "sq.ft",
  SHOWING_ALL = "Showing All",
  PROPERTIES = "Properties",
  FOR = "For",
  OR_NEARBY_LOCALITIES = "or nearby localities",
  WIP_ANALYTICS = "WIP Analytics",
  BOTH_UNSECURED = "Unsecured - Both revenue",
  BRAND_SECURED = "Secured - Brand revenue",
  LL_SECURED = "Secured - Landlord revenue",
  BOTH_SECURED = "Secured - Both revenue",
  LL_UNSECURED = "Unsecured - Landlord revenue",
  BRAND_UNSECURED = "Unsecured - Brand revenue",
  RUPEE_SYMBOL = "₹",
  SAVE_COMMISION_DETAILS = "Save commission details",
  COMMISION_DETAILS = "Commission details",
  COMMISION_DETAILS_DESC = "You can enter commission details and % here",
  AMOUNT_IN_INR = "Amount in INR",
  TARGET = "Target",
  REVENUE = "Revenue",
  REVENUE_UPDATE = "Revenue Update",
  BALANCE = "Balance",
  TOTAL_BILLED = "Total Billed",
  PERCENT_ACHIEVED = "% achieved",
  PERCENT = "%",
  MIN = "Min",
  MAX = "Max",
  WEIGHTAGE = "Weightage",
  UPDATE_BTN = "Update",
  BILLING_BTN = "Billing",
  TENTATIVE = "Tentative",
  TENTATIVE_MONTH = "Tentative Month",
  TRANSFER_IN = "Transfer in",
  CHOOSE_MONTH_TRANSFER = "Choose Month to transfer",
  CURRENT_PROJECTION = "Current Projection",
  YOU_CAN_TRANSFER_AMOUNT = "You can Transfer Billing Amount from ",
  MONTH_TO_ANY_MONTH = "month to any month",
  TRANSFER_TO_OTHER_MONTH = "Transfer to other month",
  ALL_AMOUNT_OKAY = "All Amount Okay",
  ALL_AMOUNT_QUERY = "All Amount in Query",
  ALL_AMOUNT_TENTATIVE = "All Amount in Tentative",
  ADD_CUSTOM_VALUE = "Add Custom Value",
  TRANSFER_TENTATIVE_TO_OTHER_MONTH = "You can Transfer tentative Amount to another month’s amount",
  UPDATE_BILLING_HERE = "You can Update billing amount here",
  TRANSFER_NEXT_MONTH_INSTEAD = "Transfer in next months instead",
  MOVE_TO = "Move to",
  SELECT_AGENT = "Select Agent",
  ENTER_COMMENT_HERE = "Enter comment here",
  MARK_AS_OKAY = "Mark as Okay",
  MARK_AS_QUERY = "Mark as Query",
  MARK_AS_TENTATIVE = "Mark as Tentative",
  INVOICING_AMOUNT = "Invoicing Amount",
  AREA = "Area",
  SL_NO = "Sl No.",
  REGION = "Region",
  COMMISSION = "Commission",
  AGENT = "Agent",
  BILLING_MILESTONES = "Billing Milestones",
  WIP_STATUS = "WIP Status",
  SECURED_LL_REVENUE = "Secured LL Revenue",
  SECURED_BRAND_REVENUE = "Secured Brand Revenue",
  TOTAL_SECURED = "Total Secured",
  LL_BROKERAGE_DAYS = "LL Brokerage Days",
  BRAND_BROKERAGE_DAYS = "Brand Brokerage Days",
  TOTAL_BROKERAGE_DAYS = "Total Brokerage Days",
  LANDLORD = "Landlord",
  RATE_PER_SQ_FT = "Rate/Sq.ft",
  RENT_PER_MONTH = "Rent/Month",
  OKAY_AMOUNT = "Okay Amount",
  QUERY_AMOUNT = "Query Amount",
  OKAY_PERCENTAGE = "Okay Percentage",
  QUERY_PERCENTAGE = "Query Percentage",
  IN = "in",
  HIGH_STREET_MARKETS = "High Streets/Markets",
  COMPLETION_DATE = "Completion Date",
  BILLING_MILESTONE_COMPLETION_DATE = "Billing Milestone Completion Date",
  RYTD = "RYTD",
  BALANCE_TO_ACHIEVE = "Balance to Achieve",
  OKAY = "Okay",
  QUERY = "Query",
  FLASH = "Flash",
  FREEZE_PROJECTION = "Freeze Projection",
  YES_FREEZE = "Yes! freeze",
  FREEZE_PROJECTION_TEXT = "By freezing the projection, it will become the Flash for this month. Once frozen, you cannot change the Flash for this month. Are you sure you want to freeze?",
  FREEZE_PROJECTION_TITLE = "Confirm Projection Freeze",
  SCORECARD = "Scorecard",
  DASHBOARD = "Dashboard",
  CREATED_DATE = "Created Date",
  LAST_STATUS_CHANGED = "Last Status Changed",
  REQUIREMENT_ID = "Requirement ID",
  NO = "No.",
  WIP_REVENUE_SCORECARD = "WIP Revenue Scorecard",
  YOUR_WIP = "Your WIP",
  WIP_APPROVAL_STATUS = "WIP Approval Status",
  EDIT_WIP_CAPS = "Edit WIP",
  APPROVED = "Approved",
  NEW_APPROVAL_AVL = "New approval avl",
  NOT_SUBMITTED_CHANGES = "Not Submitted",
  SUBMITTED_CHANGES = "Submitted Changes",
  SUBMITTED_NEW_WIP = "Submitted New WIP",
  DECLINED_CHANGES = "Declined Changes",
  DECLINED_NEW_WIP = "Declined New WIP",
  PENDING_NEW_WIP = "Pending New WIP",
  PENDING_CHANGES = "Pending Changes",
  APPROVE_WIP = "Approve WIP",
  DECLINE_WIP = "Decline WIP",
  VIEW_WIP_CHANGES = "View WIP Changes",
  REASON_TO_DECLINE_NEW_WIP = "Reasons for Declining new WIP",
  REASON_TO_DECLINE_WIP = "Reasons for Declining changes",
  REASON_TO_DECLINE = "Please mention reasons for declining",
  DECLINE = "Decline",
  NEW_WIP_DECLINE_TEXT = "If you decline, your feedback will be sent to the brand manager and WIP will not be shown to the central team unless approved. Are you sure you want to decline?",
  WIP_DECLINE_TEXT = "If you decline, your feedback will be sent to the brand manager and current WIP will remain unchanged. Are you sure you want to decline?",
  PLEASE_COMMENT_TO_DECLINE = "Please mention reasons for declining",
  PLEASE_COMMENT_TO_REJECT = "Please mention reasons for rejecting",
  DONT_APPROVE = "Don't Approve",
  YES_APPROVE = "Yes, Approve",
  APPROVE_CONFIRMATION = "Approve confirmation",
  APPROVE_NEW_WIP_TEXT = "If you Approve, the WIP will be shown to the Central Team for further processing. Are you sure you want to approve?",
  APPROVE_WIP_TEXT = "If you approve changes, the system will update the current WIP for all the stakeholders. Are you sure you want to approve?",
  LIVE_WIP_BM_LEAD_TEXT = "This is a Live WIP, Any changes made will be reflected for all the stakeholders.",
  NEW_WIP_APPROVAL_BM_LEAD_TEXT = "This is a new WIP request, Once approved by you this will be shown to the Central Team for further processing.",
  COMPARING_BM_CHAMGES_BM_LEAD_TEXT = "You're comparing brand manager's suggestions with originals value to decide for approval.",
  REASON_FOR_DECLINE_TEXT = "Reason for Decline-",
  LIVE_WIP_BM_TEXT = "This is a Live WIP, Any changes made will require approval from the Brand Manager lead.",
  PENDING_WIP_BM_TEXT = "This WIP is under Approval, once approved by Brand manager lead this WIP will be updated.",
  PENDING_CHANGES_WIP_BM_TEXT = "You're comparing your suggestions with originals value.",
  ORIGINAL_VALUES = "Original Values",
  PROPOSED_CHANGES = "Proposed Changes",
  RE_SUBMIT_FOR_APPROVAL = "Resubmit for Approval",
  APPROVE = "Approve",
  SUBMIT_FOR_APPROVAL = "Submit",
  SUBMIT_CONFIMATION_TEXT = "You are about to submit WIP with changes. If submitted, we will send it for Brand Manager Lead approval. Once approved, it will be live. Are you sure you want to submit?",
  NEW_WIP_SUBMIT_CONFIRMATION_TEXT = "You are about to submit WIP. If submitted, we will send it for Brand Manager Lead approval. Once approved, it will be live. Are you sure you want to submit?",
  DONT_SUBMIT = "Don’t Submit",
  MIXED_ANALYTICS = "Mixed Duration",
  EDIT_CHANGES = "Edit Changes",
  USER_FLOW = "userFlow",
  RESET = "Reset",
  APPLY = "Apply",
  ALL_TEXT = "All",
  PARKING = "Parking",
  PROJECT_NAME = "Project Name",
  PROJECT_ID = "Project ID",
  ADD_COMMENT = "Add Comment",
  ADD_NEW_COMMENT_HERE = "Add New Comment Here",
  EXPORT_DATA = "Export Data",
  PREVIEW = "Preview",
  IMPORT_REVENUE_SHEET = "Import Revenue Update Sheet",
  IMPORT_INVOICE_SHEET = "Import Invoice Sheet",
  IMPORT_COLLECTION_SHEET = "Import Collection Sheet",
  IMPORT_N_SYNC = "Import & sync ",
  UPDATE_SHEET = " Update sheet",
  INVOICE = "Invoice",
  INVOICES = "Invoices",
  SHEET = "Sheet",
  SAMPLE = "Sample",
  WHAT_HAPPENS_WHEN_WE = " What happens when we",
  REVENUE_1 = "1. Few Matched Deals will be marked as Okay, means finance has received all the required Documents.",
  REVENUE_2 = "2. Few Matched Deals will be marked as Query which means some of the required Documents are not available.",
  INVOICE_1 = "1. Matched Deals will be confirmed as Okay, means invoice is generated for these deals.",
  INVOICE_2 = "2. Automatically System will add Invoice number, invoice date and invoice value for all the matched deals.",
  INVOICE_3 = "3. Matched deal Status would be fixed to Okay and deal will be sent to collections for the collection process",
  COLLECTION_1 = "1. The system will automatically update outstanding, projection and collected amount for all matched deals",
  COLLECTION_2 = "2. The system will show all un-allocated deals for which invoice number is missing",
  SYNC_FAILED = "Syncing Failed!",
  POSSIBLE_REASON = "Possible Reason (s)-",
  IMPORTED_FILE = "Imported file",
  INVOICE_DETAILS = "Invoice Details",
  UNDERSTOOD = "Understood",
  DEALS = "deals",
  LL_BROKERAGE = "LL Brokerage",
  BRAND_BROKERAGE = "Brand Brokerage",
  TOTAL_PROJECTION = "Total Projection",
  DEAL_DETAILS = "Deal Details",
  FOR_THE_CLENTS = "for the Clients",
  PORJECTION_DISABLED_TEXT = "You can not change projection Since it is marked okay after importing Invoice sheet",
  UPLOAD_N_SYNC = " Upload & Sync",
  CONFIRM_LEAVE_IMPORT_1 = "You are about to leave syncing imported",
  CONFIRM_LEAVE_IMPORT_2 = "sheet, are you sure you want to leave?",
  LEAVE_WITHOUT_SYNCING = "Leaving without syncing?",
  YES_LEAVE = "Yes! Leave",
  NO_WAIT = "No! Wait",
  LETS_START_SYNC = "Let Us Start Syncing",
  GO_BACK_TO_WIP = "Go Back to WIP",
  YES_PROCEED = "Yes! Proceed",
  YES_CANCEL = "Yes! Cancel",
  CANCEL_SYNC = "Cancel Syncing?",
  PARTIAL_SYNC = "Partial Syncing?",
  CANCEL_SYNC_TEXT = "You are just one step away to updating all the matching WIPs. Are you sure you do not want to finish syncing?",
  FINISH_SYNC = "Finish Syncing",
  FULL_MATCHING_TEXT_2 = "We can proceed with all matching deals, once synched we will update values of all matching WIP",
  ALL_DEAL_VALUE_IN = "All Deal’s Values In",
  SHEET_MATCHED_WITH_WIP_VALUES = "Sheet Matched With WIP Values",
  FINISH_PARTIAL_SYNC = "Finish Partial Syncing",
  DEALS_FOUND_ERROR_TEXT = "Deals Found With Missing/Invalid Primary Information In Sheet",
  DEALS_FOUND_ERROR_TEXT_2 = "We cannot proceed with missing/Invalid primary information. Please Re-import the sheet or export this data and import it with all the correct information",
  DEALS_VALUE_IN = "Deal’s Values In",
  SHEETS_MISMACH_VALUE = " Sheet Mismatching With WIP Values",
  WE_CAN_PROCCED_WITH = "We can proceed with",
  SHEET_MISMATCHED_TEXT_2 = "matching deals and update WIP, or else Either modify WIP values or request finance team to cross check values for these",
  DEALS_N_REPORT = "deals and re-import",
  BEFORE_YOU_PROCEED = "Before you proceed",
  INVOICE_IMPORT_WARNING_TEXT = 'Once a matched deal is confirmed as "Okay," it will be sent to collection. Afterward, you cannot reverse its status to "Query" or "Projection".',
  NO_OF_LL = "No of Landlords",
  NO_PROPERTIES_FOUND = "No properties found",
  ACCEPT_EXCEL_FILE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
  INITIAL_PROJECTION = "Initial Projection",
  LL = "LL",
  COLLECTION_ANALYTICS = "Collection Analytics",
  GO_BACK_TO_COLLECTIONS = "Go Back To Collections",
  SYNC_CONFIRM = "Syncing Confirmation",
  COLLECTION = "Collection",
  COLLECTIONS = "Collections",
  COLLECTION_SHEET_ERROR_1 = "Deals Found With Missing/Invalid Primary Information In Collection Sheet",
  COLLECTION_SHEET_ERROR_2 = "We cannot proceed further with missing primary information. Please re-import the sheet or export this data and import it with all the correct information",
  COLLECTION_SHEET_MISMATCH_1 = "Deal In Collection Sheet Mismatching With Collections Listing",
  COLLECTION_SHEET_MISMATCH_2 = "We can proceed with 15 matching deals and update Collections, or else request finance team to cross check these",
  COLLECTION_SHEET_MISMATCH_3 = "deals and re-import with correct values",
  COLLECTION_SHEET_MATCHED_1 = "All Deal’s In Collection Sheet Matched With Collections Listing",
  COLLECTION_SHEET_MATCHED_2 = "We can proceed with all matching deals, once synched we will update values of all matching Collections",
  COLLECTION_PARTIAL_MODAL_TEXT = "matched deals from the collection sheet will be synced. If done, the values will be updated and cannot be changed later on. Are you sure?",
  ONLY = "Only",
  COLLECTION_CANCEL_MODAL_TEXT = "You are just one step away from syncing all the matched deals. Are you sure you want to cancel?",
  COLLECTION_SUCCESS_MODAL_TEXT = "Once matched deals are synced, their values will be updated, and cannot be changed later on. Are you sure you want to sync?",
  WIP_SHEET_ALL_DEAL_MISMATCH_1 = "All Deal’s Values In",
  WIP_SHEET_ALL_DEAL_MISMATCH_2 = "We can not proceed further with 0 matching, Either modify WIP values or request finance team to cross check",
  SHEET_VALUES = "sheet’s values.",
  COLLECTION_ALL_DEAL_MISMATCH_1 = "All Deals In Collection Sheet Mismatching With Collections Listing",
  COLLECTION_ALL_DEAL_MISMATCH_2 = "We can not proceed further with 0 matching; please request the finance team to cross-check the collection sheet's values.",
  OUTSTANDING = "Outstanding",
  COLLECTED = "Collected",
  OUTSTANDING_AMT = "Outstanding Amount",
  LEGA_ISSUE_WRITE_OFF = "Legal /Write off/Issues",
  INVOICE_NO = "Invoice No.",
  INVOICE_DATE = "Invoice Date",
  TOTAL_COLLECTIONS = "Total Collections",
  CURRENT_OUTSTANDING = "Current Oustanding",
  COLLECTION_HISTORY = "Collection History",
  WRITE_OFF_WARNING = "Collection projection for all the months will be removed once you mark as write off, are you sure you want to mark Write off?",
  CONFIRNATION = "Confirmation",
  ISSUES_WARNING = "Let us aim to bring this money as there are chances of getting it.",
  PLEASE_MNTN_REASON = "Please mention reasons for",
  REASON_WILL_BE_SHOWN = "Reason will be shown in comment section",
  STATUS = "Status",
  CLIENT_NAME = "Client name",
  TOTAL_OUTSTANDING_AMOUNT = "Total Outstanding Amount",
  CURRENT_OUTSTANDING_AMOUNT = "Current Outstanding Amount",
  EXPECTED_COLLECTION_DATE = "Expected Collection Date",
  TOTAL_COLLECTIONS_AND_HISTORY = "Total Collections And History",
  EDIT_PROJECTION = "Edit Projection",
  SAVE_DEAL_DEATILS = "Save Deal Details",
  ADD_DEAL_DEIAILS_HELPER_TEXT = "These details will help us distinguish this deal from other similar deals",
  ADD_DEAL_DETAILS = "Add deal Details",
  HERE = "Here",
  ONCE_FREEZING_DONE = "Once Freezing projection is done-",
  PROJECTION_WILL_BECOME_FLASH = "1. Projection will become Flash for ",
  AND_TENTATIVE_MONTH = "and its tentative month.",
  YOU_CANT_CHANGE_FLASH = "2. You can not change the Flash however Shifting projection from future to current month's Flash creates the current projection, combining the fixed Flash with the added amount.",
  FLASH_CALCULATION = "Flash Calculation As per Current Projection",
  ISSUES = "Issues",
  LEGAL = "Legal",
  WRITE_OFF = "Write Off",
  COLLECTION_RESULTS = "Collection Results",
  IRF_RESULTS = "IRF Results",
  RE_UPLOAD = "Re-upload",
  FLOOR_PLANS = "Floor Plans",
  DOWNLOAD_ALL = "Download All",
  PLEASE_WAIT_SOMETIME = "Please Wait Sometime",
  ERROR_DOWNLOADING = "Error Downloading",
  ERROR_GENERATING_BROCHURE = "Error Generating Brochure",
  ERROR_GENERATING_ZIP = "Error Generating Zip",
  TOTAL_BILLING = "Total Billing",
  WIP_HISTORY_COLUMN_HEADING = "Total WIP, Billing & History",
  WIP_COLLECTION_COLUMN_HEADING = "Total Collections & History",
  BROKERAGE_DAYS = "Brokerage Days",
  NO_FLOOR_PLANS_FOUND = "No floor plans are available.",
  ACHIEVED = "Achieved",
  TOTAL_INVOICES = "Total Invoices",
  INVOICE_VALUE = "Invoice Value",
  TOTAL_COLLECTED = "Total Collected",
  MARKED_AS_WRITE_OFF = "Marked as Write off",
  MARKED_AS_LEGAL = "Marked as Legal",
  MARKED_AS_ISSUES = "Marked as Issues",
  PLEASE_SELECT_ONE_BILLING_MILESTONE = "Please select atleast one billing milestone",
  PLEASE_ENRTER_DUE_DATE = "Please enter due date for every billing milestone",
  NO_ORIGINAL_VALUE = "No Original values",
  STEP_1_OFF_2 = "Step 1/2, ",
  STEP_2_OFF_2 = "Step 2/2, ",
  UPDATE_AND_NEXT_STEP = "Update Status And Go To Final Step",
  GOBACK_TO_STEP_1 = "Go Back To Step 1 ",
  SUBMIT_FOR_APPROVAL_WIP = "Submit for Approval",
  SYNC_COLLECTION_IN_PROGRESS = "Syncing collections sheet in progress",
  SYNCING = "Syncing",
  SHEET_IN_PROGRESS = "sheet in progress",
  SELECT_BILLING_MILETSONES = "Select Billing Milestone",
  FREEZE_PROJECTION_ERROR = "Freezing Projection error!",
  FREEZE_PROJECTION_ERROR_DESCRIPTION = " deals have projections not matching their outstanding amounts. Kindly update the projections and then freeze it.",
  SAVE_PROJECTIONS = "Save Projections",
  UPDATE_PROJECTION_FOR = "Update Projection for",
  DUPLICATE_MONTH = "Duplicate month added.",
  PROJECTION_TOTAL_ERROR = "Total Projection should equal to outstanding.",
  INVOICE_NUMBER = "Invoice Number",
  TOTAL_INVOICE_VALUE = "Total Invoice Value",
  PENDING_COLLECTION_AMNT = "Pending Collection Projection",
  MARK_COLLECTION_IN_PERCENT = "Mark Collection in %",
  MARK_FEE_IN_RUPEEE = "Mark fee share in ₹",
  MARK_COMMISSION_IN_RUPEEE = "Mark commission in ₹",
  OUTSTANDING_COLLECTION_PROJECTION_DETAILS = "Outstanding And Collection Projection Details",
  COLLECTION_FLASH_FIXED_HELPER_TEXT = "However shifting projection from future to current month's flash creates the current projection, combining the fixed flash with the moved projection.",
  CREATE_NEW_WIP = "Create New WIP",
  START_CREATING_WIP = "Start Creating WIP",
  ADD_MALL = "Add Mall",
  SHOP_DETAILS = "Shop Details",
  MALL_DETAILS = "Mall Details",
  DEAL_TYPE = "Deal Type",
  MALL_NAME = "Mall Name",
  MALL_LOCATION = "Mall Location",
  COLLECTED_WITHOUT_GST = "Collected (Without GST)",
  TOTAL_CURRENT_OUTSTANDING = "Total Current Outstanding",
  DEBTOR = "Debtor",
  SHEET_ROW_MATCHED = "Matched",
  SHEET_ROW_PREVIOUS = " Previously Synced",
  SHEET_ROW_MISMATCHED = " Mismatched!",
  SHEET_ROW_ERROR = "Error!",
  CITIES = "Cities",
  WO_GST = " (Without GST)",
  COLLECTED_FEE_YTD = "Collected fee YTD",
  COLLECTED_FEE_MTD = "Collected fee MTD",
  TOTAL_COLLECTED_FEE = "Total Collected fee",
  CURRENT_OUTSTANDING_FEE = "Current Outstanding Fee",
  DUE_DATE = "Due Date",
  COLLECTION_DEBT = "Collections - Debtors",
  COLLECTION_BM = "Collections - Brand managers",
  COLLECTION_ILW = "Collections - Issues, Legal and Write off",
  VIEW_ALL_DEBTOR_DEALS = "View All Debtor Deals",
  VIEW_BM_DEALS = "View All Brand Managers Deals",
  VIEW_BAD_DEBT_DEALS = "View All Issues, Legal and Write off Deals",
  VIEW_ALL_ONGOING_DEALS = "View All Ongoing Deals ",
  WITH_GST = "(With 18% GST)",
  WIP_RESULTS = "WIP Results",
  DOWNLOAD_WIP_SHEET = "Download WIP Sheet",
  DOWNLOAD_COLLECTION_SHEET = "Download Collection Sheet",
  ERROR_GENERATING_SHEET = "Error Generating Sheet",
  SEARCH_WIP = "Search WIP",
  SEARCH_WIP_PARAM = "Search WIP by Client's name, Client's legal name or Project ID",
  SHOPS = "Shops",
  SEARCH_INVOICE_PARAM = "Search deal by it’s Client name, Invoice no. or Project ID",
  SERACH_DEALS = "Search Deals",
  RESET_SEARCH_RESULTS = "Reset Search results",
  EXPORTING = "Exporting",
  DRAFT_WIP = "Draft WIP",
  FEW_DETAILS_IRF = "Few Details Before We Start Invoice Request",
  UPDTAE_IRF_TITLE = "Update Clients Name and Billing Milestone",
  START_INVOICE_REQ = "Start Invoice Request",
  UPDATE_DETAILS = "Update Details",
  YOU_CAN_CHANGE_LATER = "You can change these details later in the process",
  BILLING_MILESTONE = "Billing Milestone",
  CONTACT_DETAILS = "Contact Details",
  PRO_N_BROERAGE_DETAILS = "Property & Brokerage Details",
  SUPPORTING_DOCS = "Supporting Documents",
  STEP_1_CONTACT_DETAIL = "Step 1: Contact Details",
  STEP_2_PRO_N_BRO = "Step 2: Property & Brokerage Details ",
  DONT_REQUEST = "Don’t Request",
  REQUEST_NEW_INVOICE = "Request New Invoice",
  DELETE_DRAFT = "Delete Draft",
  CLIENT_GST = "Client GST",
  PROPERTY_STATE = "Property State",
  FEE_AMOUNT = "Fee Amount",
  PROFIT_CENTER = "Profit Center",
  IRF_APPROVAL_STATUS = "IRF Approval Status",
  REQUEST_DATE = "Requested Date",
  CONFITM_IRF_REVOKE_TITLE = "Attention! You are about to revoke an Invoice Request",
  CONFIRM_IRF_REVOKE_TEXT = "If you revoke the request, it will no longer be shown to finance for approval. Are you sure you want to revoke the request?",
  YES_REVOKE = "Yes! Revoke",
  NO_DONT = "No! Don't",
  SUBMIT_IRF_CNF_TITLE = "Final Confirmation before we send to finance approval!",
  SUBMIT_IRF_LEAD = "Final Confirmation before we send It to lead approval!",
  SUBMIT_IRF_CNF_TEXT = "Please double-check that all the information in the IRF is correct and matches the attached supporting documents",
  SUBMIT_TO_FIN = "Submit To Finance",
  WAIT_GO_BACK = "Wait! Go Back",
  CONFIRM_APPROVED_TITILE = "Are you sure you want to approve this request?",
  CONFIRM_APPROVED_TEXT = "If approved, you will need to upload SAP generated invoice to ensure all stakeholders can access it in their respective portals.",
  LEAD_APPROVAL_TEXT = "If approved, the request will be sent to the finance team. Upon their approval, the invoice will be generated.",
  CONFIRM_INVOICE_APPROVED_TEXT = "If approved, you will need to upload the SAP-generated invoice later to ensure all stakeholders can access it in their respective portals.",
  REJECT_IRF_TITLE = "Are you sure you want to Decline this request?",
  REJECT_IRF_TEXT = "If declined, the agent will use your Reasons to modify and resend the invoice request.",
  CNFRM_DRFAT_DEL_TITLE = "Confirm Draft Deletion?",
  CNFRM_DRFT_DEL_TEXT_1 = "Deleting this draft will remove it permanently. Remember, you can always return to edit drafted request in the",
  SECTION = "section",
  STEP_3_DOCUMENTS = "Step 3: Documents",
  STEP_4_SUMMARY = "Step 4: Summary",
  PENDING = "Pending",
  REQUEST_APPROVED = "Request Approved",
  DECLINED = "Declined",
  INVOICE_GENERATED = "Invoice Generated",
  VIEW_GENERATED_INVOICES = "View Generated Invoices",
  UPLOAD_GENERATED_INVOICES = "Upload Generated Invoices",
  VIEW_IRF_AND_DOCUMENTS = "View IRF and Documents",
  INSPECT_DETAILS_AND_DOCUMENTS = "Inspect Details and Documents",
  APPROVE_INVOICE_REQUEST = "Approve Invoice Request",
  DECLINE_INVOICE_REQUEST = "Decline Invoice Request",
  REVOKE_INVOICE_REQUEST = "Revoke Invoice Request",
  PENDING_REQUEST = "Pending Invoice Request",
  DECLINED_REQUEST = "Declined Invoice Request",
  APPROVED_REQUEST_AND_GENERATED_INVOICES = "Approved & Invoiced Request",
  MORE = "more",
  UPLOAD_INVOICE = "Upload Invoice",
  LET_US_IMPORT = "Let Us Import",
  INVOICE_DETAILS_AND_INVOICE_UPLOADED = "Once Invoice details and Invoice are Uploaded, it will be visible to all the stakeholders",
  ACCEPT_PDF_FILE = "application/pdf",
  INVOICE_NO_IS_MANDATORY_BEFORE_UPLOADING = "Invoice no. is mandatory before uploading",
  IRF_SUMMARY_CONTACT = "1- Contact Details",
  IRF_SUMMARY_PROPERTY_AND_BROKERAGE = "2- Property & Brokerage Details",
  IRF_SUMMARY_DOCUMENTS = "3- Supporting Documents",
  VIEW_INVOICES = "View Invoices",
  GO_TO_PREVIOUS_STEP = "Go to previous step",
  SUBMIT_REQUEST_TO_FINANCE = "Submit Request To Finance",
  SUBMIT_REQUEST_TO_LEAD = "Submit Request To Lead",
  APPROVED_IRF_MESSAGE = "This is the approved IRF. Once the invoice is generated, it will be shown to all stakeholders.",
  PENDING_IRF_MESSAGE = "This IRF is for Finance approval. Upon their approval, the invoice will be generated.",
  SEARCH = "Search",
  SEARCH_IRF_PENDING = "Search Request by it’s Client name, GST no. or Project ID",
  SEARCH_IRF_APPROVED = "Search Invoice by it’s Client name, Invoice no., GST no. or Project ID",
  CLIENT_LEGAL_NAME = "Client’s Legal Name",
  VIEW_EDIT_REQUEST = "View/Edit Request",
  VIEW_PAYMENT = "View Payment",
  VIEW_INVOICE = "View Invoice",
  IRF_LEAD_PENDING = "This IRF is for your approval. Once approved, it will be sent to finance for approval. If finance approves, the invoice will be generated.",
  IRF_INVOICE_GENERATED = "Invoice Generated on ",
  IRF_BM_TO_LEAD_PENDING = "This IRF is for Lead approval. Once approved, it will be sent to finance for approval. If finance approves, the invoice will be generated.",
  SND_FR_APPRV = "Send For Approval",
  WITHOUT_TAX = "W/O Tax",
  WITH_18_PERCENT_TAX = "With 18% Tax",
  PAYMENT_DATE = "Payment Date",
  CHEQUE_DATE = "Cheque Date",
  CHEQUE_DEPOSIT_DATE = "Cheque Deposit Date",
  DESCRIPTION = "Description",
  UTR_NO = "UTR Number",
  AMOUNT_AS_PER_BANK_STATEMENT = "Amount received",
  COMMENT_AND_HISTORY = "Comment and History",
  STATUS_AND_ACTION = "Status and Action",
  CLIENT = "Client",
  CLAIM_NEW_PAYMENT = "Claim New Payment",
  CLAIM_PAYMNENT_HEADING = "If payment is received & confirmed by finance, it will be added to the payments list",
  SEND_REQ_TO_FINANCE = "Send Request to Finance",
  ADD_MORE_INVOICES = "Add More Invoices",
  MULTIPLE_INVOICES_INVOLVED = "Multiple invoices involved",
  UPLOAD_PAYMENT_PROOF = "Upload Payment Proof",
  UPLOAD_CHEQUE_PROOF = "Upload Cheque Proof",
  UPLOAD_DEPOSIT_PROOF = "Upload Deposit Proof",
  PAYMENTS_CLAIM = "Payments Claim",
  PAYMENTS_RESULTS = "Payments Results",
  SEARCH_PAYMENT = "Search Payment",
  PAYMENTS = "Payments",
  INSPECT_PAYMENT = "Inspect Payment Request",
  NEW_REQ_TO_BE_APPROVED = "New payment request to be approved",
  RECIEVED_AMOUNT = "Received Amount",
  CLAIM_PAYMENT_INSPECTION_1 = "1- Payment details to be claimed",
  CLAIM_PAYMENT_INSPECTION_2 = "2- Claim Details",
  TOTAL = "Total",
  DEPARTMENT = "Department",
  RETAIL = "Retail",
  CITY_LEAD = "City Lead",
  CONFIRM_APPROVED_PAYMENT_TITLE = "Final step before you approve the request",
  PAYMENT_APPROVAL_TEXT = "If request is approved, the Received amount will be claimed and adjusted to corresponding Invoice.",
  PAYMENT_APPROVAL_TEXT_2 = " Chosen month will be considered as payment accounting month irrespective of payment claim & payment date. ",
  CHOOSE_PAYMENT_ACC_MONTH = "Choose payment accounting month-",
  APPROVE_CLAIM = "Approve Claim",
  DECLINE_CLAIM = "Decline Claim",
  INSPECT_REQUEST = "Inspect Request",
  VIEW_DECLINED_REQUEST = "View Declined Request",
  VIEW_APPROVED_REQUEST = "View Approved Request",
  CONFIRM_DECLINE_PAYMENT_TITLE = "Choose reason for declining request",
  PLEASE_MENTION_REASONS = "Please mention reasons",
  SEARCH_PAYMENTS_PLACEHOLDER = "Search payment by it’s UTR no. or Invoice no.",
  VIEW_CLAIMED_PAYMENTS = "View Claimed Payments",
  CHOOSE_OTHER_DOCS = "Choose Other Documents",
  AMOUNT_ADJUSTED_FOR_INVOICE = "Amount is adjusted against corresponding Invoice on ",
  CLAIM_APPROVED = "Claim Approved ",
  FINANCE_DECLINED = "Finance Declined ",
  COMMISSION_RECORD = "Commision Record",
  WIP_STEP_0_HEADING = "Step: 0 WIP type and client details ",
  BILLING_CLIENT = "Billing Client",
  LOCATION = "Location",
  CONSULTING = "Consulting",
  BRAND_CONATCT_DETAIL = "Step:1 Brand Contact Details",
  LL_CONATCT_DETAIL = "Step:1 Landlord Contact Details",
  BOTH_CONATCT_DETAIL = "Step:1 Brand & Landlords Contact Details",
  ADD_MORE_LL = "Add More Landlords",
  ADD_MORE_BRAND_POC = "Add More Brand POC",
  BRAND_POC = "Brand POC",
  BRAND_DETAILS = "Brand Details",
  TOTAL_FY = "Total FY",
  ONE_BRAND_CD = "1- Brand Contact Details",
  ONE_LL_CD = "1- Landlord Contact Details",
  ONE_BOTH_CD = "1- Brand & Landlords Contact Details",
  LL_N_BARND = "Landlord & Brand",
  LANDLORD_S = "Landlord (s)",
  INVOICE_REQ_TYPE = "Invoice Request Type",
  BOTH_ADD_DIFFERENT = " Both bill & ship address are different ",
  CLIENT_MAY_FACE_ISSUE = "Client may face issue in GST input- please recheck and submit",
  ONLY_FOR_INVOICE = "Only for invoice ",
  REVENUE_N_INVOICE = "Revenue and Invoice",
  REVENUE_N_INVOICE_TEXT = "Service completion documents are attached along with IRF details",
  REVENUE_REC_APP_INVOICE = "Revenue recognition for approved invoice",
  REVENUE_REC_APP_INVOICE_TEXT = "Service completion documents are attached for approval",
  SRVC_COMPLETION_TEXT = "Service completion documents will be submitted later for revenue recognition",
  TP_INVOLVED = "Third Party Involve",
  AGENT_REVENUE_ALLOCATION = "Agent Revenue Allocation for Commission",
  THIRTY_DAYS = "30 Days",
  OTHER_DETAILS = "Other Details",
  BILLING_SHIPPING_ADD_DIFF = "Client Shipping address and Billing address are different",
  CLIENT_SHIP_ADD = "Client’s Shipping address",
  CLIENT_BILLING_ADD = "Client’s Billing address",
  TO_MATCH_WITH_GST = "To match with GSTN of client",
  GST_N_DETAILS = "GST no. and Address",
  CLIENT_POC_DETAILS = "Client’s POC details",
  CLIENT_POC_NAME = "Client’s POC name",
  ANAROCK_POC_DETAILS = "Anarock POC details",
  APPROVE_N_RECOGNIZE = "Approve Request & Recognise Revenue",
  INSPECTION_MODE = "Inspection Mode",
  INVOICE_RE = "Invoice Request",
  IRF_DEATILS_TB_VALIDATED = "IRF details To be validated",
  VALIDATE_IRF = "Validate IRF details with supporting Documents",
  IRF_ONE_CONTACT = "1-Contact Details-",
  IRF_PROPERTY_DETAILS = "2- Property & Brokerage Details",
  CUSTOM_SELECTION = "Custom Selection",
  CHOOSE_REV_REC_MONTH = "Choose revenue recognition month-",
  REV_REC_MONTH_TEXT = "Chosen month will be considered as revenue recognition month irrespective of invoice request date.",
  INVOICE_N_AMNT_DETAILS = "Invoice & Amount Details",
  REVENUE_RECOGNITION = "Revenue Recognition",
  INVOICE_STATUS = "Invoice Status",
  INVOICE_REQUEST_NATURE = "Invoice request nature",
  SERVICE_COMPLETION_PROOF = "Service Completion Proof",
  UPLOAD_SERVICE_COMPLETION_DOCUMENTS = "Upload Service Completion Documents",
  UPLOAD_SERVICE_COMPLETION_DOCUMENTS_HEADING = "What happens once we upload service completion documents?",
  UPLOAD_SERVICE_COMPLETION_DOCUMENTS_SUB_HEADING = "If it is approved by finance, revenue will be marked as recognised",
  APPROVE_REQUEST_AND_REVENUE_RECOGNISED = "Approve request & recognise revenue",
  APPROVE_REQUEST_BUT_REVENUE_DEFERRED = "Approve request",
  SUBMIT_SERVICE_COMPLETION_DOCS = "Submit service completion documents",
  TOTAL_CLAIMING = "Total Payment Claiming",
  CP_OTHER_DETAILS = "3- Other Details",
  TOTAL_PAST_COLLECTION = "Total Past Collection",
  PAYMENT_CLAIMING = "Payment Claiming",
  STD_TDS = "Standard TDS",
  TDS_CLAIMING = "TDS Claiming",
  HISTORY = "History",
  ADD_N_LL = "Add New LandLord",
  DELETE = "Delete",
  ADD_NEW_GST_NO = "Add new GST no.",
  GST_ADDED_SUCCESSFULLY = "GST no. added successfully",
  ADDRESS_ADDED_SUCCESSFULLY = "Address added successfully",
  PAN_ADDED_SUCCESSFULLY = "PAN no. added successfully",
  GST_UPDATED_SUCCESSFULLY = "GST no. updated successfully",
  CHANGES_WILL_APPLY_TO_FUTURE = "Changes will apply to future deals only",
  EXISTED_UNCHANGED = "Existing deals data will remain unchanged.",
  EDIT_LL = "Edit LandLord Details",
  EDIT_DETAILS = "Edit Details",
  Add_LL = "Add LandLord",
  ADD_NEW_POC = "Add New POC",
  ADD_NEW_CLIENT_POC = "Add New Client's POC",
  POC_ADDED_SUCCESSFULLY = "POC added successfully",
  ADD_NEW_BRAND_POC = "Add New Brand's POC",
  EDIT_BRAND_POC = "Edit Brand's POC",
  EDIT_BRAND = "Edit Brand",
  POC_EDITED_SUCCESSFULLY = "POC edited successfully",
  ADD_NEW_ADDRESS = "Add New Address",
  EDIT_GST_DETAILS = "Edit GST Details",
  VIEW_EDIT_WIP = "View/Edit WIP",
  SEND_LEAD_APPROVAL = "Send for lead's approval",
  DELETE_WIP = "Delete WIP",
  FINAL_CONF = "Final Confirmation",
  DEL_DRAFT_WIP_TEXT = "Are you sure you want to delete this draft WIP? This action cannot be undone, and the draft will be permanently deleted.",
  YES_CONFIRM = "Yes! Confirm",
  MARKET_BENCHMARKING = "Market Benchmarking",
  MALL = "Mall",
  AVG_RENTAL = "Avg. Rental",
  AVG_RENTAL_INLINE_GF = "Avg Rental Inline GF",
  AVG_RENTAL_ANCHOR_GF = "Avg Rental Anchor GF",
  INR_SF_MONTH = "INR/ sq.ft/ Month",
  SALE = "Sale",
  INR_MONTH = "INR/ Month",
  ATD = "ATD",
  PERIOD_FOR_ATD = "Period for ATD",
  MALL_ATD = "Mall ATD",
  FY_TURNOVER = "FY Turnover",
  INR = "INR",
  FOOTFALLS = "Footfalls",
  REMARKS_NOTES = "Remarks/Notes",
  HIGH_STREET = "High Street",
  RENTAL = "Rental",
  COVERED_AREA = "Covered Area",
  TRADING_DENSITY = "Trading Density",
  WITHOUT_GST = "Without GST",
  INVOICED_TILL_DATE = "Invoiced till date",
  FEE = "Fee",
  EKYC_MODAL_HEADING = "e-KYC for Billing Client ",
  SEND_EKYC_LINK = "Send e-KYC link to client",
  WHAT_HAPPENS_KYC = "What Happens When You Initiate e-KYC?",
  EKYC_LINK_SHARE_EMAIL = "e-KYC link will be shared on this email",
  ESIGN_LINK_SHARE_EMAIL = "e-sign link will be shared on this email",
  KYC_MODAL_BODY_1 = "1. The client will receive an email with a link for PAN card e-KYC.",
  KYC_MODAL_BODY_2 = "2. Once the client completes e-KYC, you will receive an email for approving the e-KYC.",
  KYC_MODAL_BODY_3 = "3. If the client refuses e-KYC, you can complete KYC offline by uploading documents on their behalf.",
  UPLOAD = "Upload",
  OFFLINE_KYC_MODAL_HEADING = "Offline KYC for Billing Client",
  WHAT_HAPPENS_OFFLINE_KYC = "What Happens When You Initiate Offline KYC?",
  OFFLINE_KYC_MODAL_BODY_1 = `1. The KYC status will become "Pending on Finance".`,
  OFFLINE_KYC_MODAL_BODY_2 = `2. Once you request an invoice, and if it is approved by finance, the KYC status will change to “Approved”.`,
  KYC_DOCUMENT = "KYC Document",
  FEE_LETTER = "Fee letter",
  CLIENT_TYPE = "Client type",
  FEE_LETTER_TYPE = "Fee letter type",
  FEE_LETTER_STATUS = "Fee letter status",
  LEGAL_TYPE = "Legal type",
  KYC = "KYC",
  KYC_TYPE = "KYC type",
  IDENTITY_PROOF = "Identity proof",
  KYC_STATUS = "KYC status",
  OTHER_DOCUMENTS = "Other documents",
  DOCUMENT_NAME = "Document name",
  PURPOSE = "Purpose",
  ACTION = "Action",
  PAN_CARD = "PAN Card",
  AADHAR_CARD = "Aadhar Card",
  CLIENT_PAN_NO = "Client's PAN no.",
  CLIENT_GST_NO = "Client's GST no.",
  CHOOSE_OPTION = "Choose Option",
  VIEW_REQUEST = "View Request",
  VIEW_COLLECTIONS = "View Collections",
  CONFIRMATION_REQUIRED = "Confirmation Required!",
  KYC_CONFIRMATION_TEXT_1 = "Please confirm that you have carefully reviewed all the KYC details.",
  KYC_CONFIRMATION_TEXT_2 = "If you accept",
  KYC_CONFIRMATION_TEXT_3 = "'s KYC, it will be marked as approved.",
  REJECT = "Reject",
  KYC_REJECTION_TEXT = "Please give a reason for rejecting the KYC. It will be shared with the client.",
  UPLOADING_KYC_DOCUMENTS = "Uploading documents for KYC?",
  KYC_OFFLINE_KYC_TEXT_1 = "You have chosen offline KYC. Please note:",
  KYC_OFFLINE_KYC_TEXT_2 = "1. The e-KYC link previously sent to the client will be deactivated.",
  KYC_OFFLINE_KYC_TEXT_3 = "2. The client will be notified via email about this change.",
  GST_DOCUMENT = "GST Document",
  DETAILS = "Details",
  POC_DETAILS = "POC Details",
  REJECT_EKYC = "Reject E-KYC",
  ACCEPT_EKYC = "Accept E-KYC",
  KYC_DEATILS_FOR = "KYC details for ",
  CLIENT_DETAILS_AS_PER_DIGI = "Client's PAN card details as per DigiLocker",
  CLIENT_DETAILS_AS_PER_SYSTEM = "Client's details in the system",
  SUBMITTED_FOR_YOUR_APPROVAL = "Submitted for your approval",
  CLIENT_DETAILS_TO_AGENT_FOR_VERIFY = "Client KYC details are shared with the agent for verification ",
  APPROVED_KYC = "Approved KYC",
  DECLINED_KYC = "Declined KYC",
  SELECT_CLIENT_POC = "Select Client POC",
  DONE = "Done",
  COMPLETE_ONLINE_KYC = "Complete it online (e-KYC)",
  COMPLETE_BY_UPLOADING_DOCUMENTS = "Complete it by uploading documents",
  RETRY_EKYC = "Retry e-KYC",
  REMIND_CLIENT_EKYC = "Remind client for e-KYC",
  INSPECT_EKYC = "Inspect e-KYC",
  KYC_UPLOADED_SUCCESSFULLY = "KYC uploaded successfully!",
  FILL_MANDATORY_FIELDS = "Fill mandatory fields",
  CANT_BE_EDITED = "These Details Cannot Be Edited Here",
  CONTACT_TECH = "Please contact the tech team or write an email to tech@anarock.com to request changes.",
  EDITING_RESTRICTED = "Editing Restricted",
  FEE_LETTER_DETAILS = "Fee letter details",
  FEE_LETTER_GENERATION = "Fee letter generation",
  CHOOSE_FEE_LETTER_TYPE = "Choose Fee Letter Type",
  CHOOSE_FEE_INSTRUCTIONS = "Instructions Before Choosing Generating or Uploading Fee Letter",
  ONE_SYSTEM_GEN = "1. System-Generated–",
  SYSTEM_GEN_TEXT = " Once generated, both the Lead and client must either e-sign or sign offline.",
  TWO_CLIENT_OWN = "2. Customised or Client’s Own–",
  CLIENT_OWN_TEXT = " Upload the Fee Letter once it’s signed offline by both the Lead and the client.",
  FEE_LETTER_MUST_BE_SIGNED = "The fee letter must be signed by both the Lead and client",
  ONCE_FEE_LETTER_GENERATED = "Once Generated, both the Lead and client must either e-sign or sign offline.",
  CHANGE_BM_IN_WIP = "Changes will update milestones in the associated WIP",
  ENSURE_CHANGES = "Ensure these changes are necessary before proceeding.",
  CHOOSE_DOWN_FORMAT = "Choose download format",
  DOWNLOAD = "Download",
  CNFRM_E_SIGN = "Confirmation of Approval by e-Signing",
  LETS_SIGN = "Let’s e-Sign",
  WH_ON_APPROVE_ESIGN = "What happens when you approve it by e-signing?",
  LEAD_SIGN_TEXT_1 = "1. An email will be sent to the client containing your e-signed fee letter.",
  LEAD_SIGN_TEXT_2 = "2. The email will include a link for the client(s) to complete their e-signature.",
  LEAD_SIGN_TEXT_3 = "3. Once the client finishes the e-signature, the concerned agent will be notified via email.",
  CNF_ESIGN_TO_LEAD = "Confirmation of Sending Fee Letter for Lead’s Approval",
  WH_FOR_LEAD_APPROVAL = "What Happens When You Send for Lead’s Approval?",
  SEND_LEAD_FEE_LETTER_1 = "1. The city lead must approve it by e-signing the fee letter.",
  SEND_LEAD_FEE_LETTER_2 = "2. Upon approval, an email will be sent to the client (s) for their acceptance.",
  SEND_LEAD_FEE_LETTER_3 = "3. The email will include the lead’s signed fee letter and a link to complete the e-signature.",
  SEND_LEAD_FEE_LETTER_4 = "4. Once the client finishes e-signing the fee letter, you will be notified via email for your approval.",
  CLIENT_DETAILS = "Client Details",
  FEE_LETTER_INSPECTION = "Fee letter inspection",
  SYSTEM_GEN_FEE_LETTER = "System Generated Fee letter",
  UPLOAD_BULK_INVOICES = "Upload Bulk Invoices",
  UPLOAD_BULK_INVOICE_SHEET = "Upload Bulk Invoice Sheet",
  DOWNLOAD_PENDING_INVOICE = "Download Pending Invoice List",
  IMPORT_BULK_INVOICE_SHEET = "Import & Sync Bulk Invoice Sheet",
  IMPORT_BULK_INVOICE_HEADING = "What happens when we Import & Sync Bulk Invoice Sheet?",
  INVOICE_BULK_1 = "1. The system will show which pending invoices match the uploaded sheet and flag any mismatches or errors.",
  INVOICE_BULK_2 = `2. Once synced, matched invoices will move to the "Invoiced" tab, with their status updated to "Invoiced".`,
  BULK_INVOICES_SHEET = "Bulk Invoices Sheet",
  INVOICES_ZIP_FOLDER = "Invoices ZIP Folder",
  ACCEPT_ZIP_FILE = "zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed",
  GO_BACK = "Go Back",
  INVOICES_SHEET_ERROR_1 = "Deals Found With Missing/Invalid Primary Information In Bulk Invoice Sheet",
  INVOICES_SHEET_ERROR_2 = "We cannot proceed further with missing primary information. Please re-upload the sheet or export this data and import it with all the correct information",
  INVOICES_ALL_DEAL_MISMATCH_1 = "All Deals in Bulk Invoice Sheet Mismatching with Pending Invoice Requests",
  INVOICES_ALL_DEAL_MISMATCH_2 = "We can not proceed further with 0 matching, please cross-check the Bulk Invoice Sheet values.",
  INVOICES_SHEET_MISMATCH_1 = "Deals in Bulk Invoice Sheet Mismatching with Pending Invoice Requests",
  INVOICES_SHEET_MISMATCH_2 = "We can proceed with",
  INVOICES_SHEET_MISMATCH_3 = "matching deals to update pending invoice requests, or check the",
  INVOICES_SHEET_MISMATCH_4 = "mismatched deals and re-import with correct values.",
  INVOICES_SHEET_MATCHED_1 = "All Deals in Bulk Invoice Sheet Matching with Pending Invoice Requests",
  INVOICES_SHEET_MATCHED_2 = "We can proceed with all matching deals. Once synced, we will update all corresponding pending invoice requests.",
  INVOICES_PARTIAL_MODAL_TEXT = "matched deals from the Invoice sheet will be synced. If done, the values will be updated and cannot be changed later on. Are you sure?",
  FOR_REVENUE_ALLOCATION = "for revenue allocation",
  EMPLOYEE_ID = "employee id",
  PROFIT_CENTER_SMALL = "profit center",
  REVENUE_ALLOCATION_AMOUNT = "Revenue allocation amount",
  THIRD_PARTY = "Third party",
  THIRD_PARTY_SHARE = "Third party share",
  INVOLVEMENT_OF_THIRD_PARTY = "Involvement of Third Party",
  REVENUE_ALLOCATION_FOR_COMMISSION = "Revenue Allocation for Commission",
  EDIT_TDS_CLAIM_DETAILS = "Edit TDS Claim Details",
  UPDATE_TDS = "Update TDS",
  CLAIM_TDS_TEXT_1 = "The TDS claim does not match the standard TDS",
  CLAIM_TDS_TEXT_2 = "You can update the TDS amount here, or proceed with the current claimed TDS.",
  AMOUNT_BEING_CLAIMED = "Amount being claimed",
  COLLECTION_AMT = "Collection Amount",
  TOTAL_INVOICE_WITH_TAX = "Total Invoice (with tax)",
  INVOICE_REQUEST_ID = "Invoice request ID",
  DRAFT_REQUEST = "Draft Invoice Request",
}
