import { ArButton, ArModal, ArSearchBox, ButtonTypes } from "@anarock/ar-common-react";
import { ArRadioGroup } from "@anarock/ar-common-react/dist/ui-components/ar-radio";
import { Spin } from "antd";
import { INewLandLord } from "app/services/createRequirmentApi";
import { useGetLLSearchSuggestionsMutation } from "app/services/wip";
import { convertLLArrayToRadioOptions } from "app/utils/helperFunctions";
import { useToastService } from "components/toastService";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { MallListLLMallProps } from "./index.interface";
import AddLLModal from "./partials";

const LandLordListModal: React.FC<MallListLLMallProps> = ({ mallId, onSubmit, ...props }) => {
  const [addModalOpen, setAddModal] = useState(false);
  const [open, setOpen] = useState(props.open || false);
  const [value, setValue] = useState("");
  const [searchText, setSearchText] = useState("");

  const { errorToast } = useToastService();

  const [fetchSuggestedLLs, { data, isLoading }] = useGetLLSearchSuggestionsMutation();

  const fetchLLList = useCallback(
    async (text: string) => {
      if (text.length < 3) {
        return;
      }
      try {
        await fetchSuggestedLLs(text).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    },
    [errorToast, fetchSuggestedLLs]
  );

  const debouncedGetResults = useMemo(() => debounce(fetchLLList, 300), [fetchLLList]);

  useEffect(() => {
    if (searchText && open) {
      debouncedGetResults(searchText);
    }
  }, [searchText, open, debouncedGetResults]);

  useEffect(() => {
    setOpen(props.open || false);
    setAddModal(false);
  }, [props.open]);

  const toggelAddLLModal = () => {
    setAddModal(!addModalOpen);
    setOpen(!open);
  };

  const handleModalSubmit = () => {
    const obj = data?.data?.landlords?.find(({ _id }) => _id === value);
    if (obj) {
      props?.sendDataAndCloseModal?.(obj);
    }
  };

  const closeBothModals = (ll: INewLandLord) => {
    setAddModal(false);
    props?.sendDataAndCloseModal?.(ll);
  };

  return (
    <React.Fragment>
      <ArModal
        title="Choose LandLord"
        width={500}
        destroyOnClose
        submitText={"Done"}
        footer={[
          <ArButton key="1" type={ButtonTypes.Secondary} size="large" onClick={toggelAddLLModal}>
            {STRING_CONSTANTS.ADD_N_LL}
          </ArButton>,
          <ArButton key="1" size="large" onClick={handleModalSubmit}>
            Done
          </ArButton>,
        ]}
        open={open}
        {...props}
      >
        <ArSearchBox value={searchText} onChange={(e) => setSearchText(e.target?.value)} />

        <div style={{ height: 300, paddingTop: 32, overflowY: "scroll" }}>
          {isLoading ? (
            <Spin />
          ) : (
            <ArRadioGroup
              options={convertLLArrayToRadioOptions(data?.data?.landlords)}
              onChange={(val) => setValue(String(val))}
              emptyText={searchText?.length ? "No Results Found" : "Search to see Results"}
            ></ArRadioGroup>
          )}
        </div>
      </ArModal>
      <AddLLModal
        open={addModalOpen}
        onCancel={toggelAddLLModal}
        closeModal={toggelAddLLModal}
        sendDataAndCloseModal={closeBothModals}
        mallId={mallId}
      />
    </React.Fragment>
  );
};

export default LandLordListModal;
