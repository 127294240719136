import {
  ArBreadCrumb,
  ArButton,
  ArFormInputNode,
  ArInputType,
  ButtonTypes,
  FormNode,
  inputType,
  nodeType,
  varientType,
} from "@anarock/ar-common-react";
import { ArBreadCrumbItem } from "@anarock/ar-common-react/dist/ui-components/ar-breadcrumb";
import { IMall } from "app/services/wip";
import { requiredRulesOnChange } from "components/requirmentFormUi/rules";
import { BILLING_CLIENT_OPTIONS, WIP_LEASING_DEAL_OPTIONS, WIP_OPTIONS } from "constants/index";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./index.module.scss";

export const WIPOnboardingBreadCrumbs = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate(-1);
  return (
    <ArBreadCrumb className="breadCrumb">
      <ArBreadCrumbItem className="breadCrumbItem" key={STRING_CONSTANTS.HOME} onClick={handleClick}>
        {STRING_CONSTANTS.HOME}
      </ArBreadCrumbItem>
      <ArBreadCrumbItem>{STRING_CONSTANTS.CREATE_WIP}</ArBreadCrumbItem>
    </ArBreadCrumb>
  );
};

export const WIPOnboardingFooter = ({
  handleCreateWip,
  isLoading,
}: {
  handleCreateWip: () => void;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();
  const handleClick = () => navigate(-1);

  return (
    <div className={`display-flex-space-between ${styles.footer}`}>
      <ArButton onClick={handleClick} type={ButtonTypes.Outlined}>
        {STRING_CONSTANTS.DONT_CREATE}
      </ArButton>
      <ArButton onClick={handleCreateWip} loading={isLoading}>
        {STRING_CONSTANTS.START_CREATING_WIP}
      </ArButton>
    </div>
  );
};

export const getOnboardingFormLayout = (childNode: FormNode[]) => {
  return {
    type: nodeType.container,
    elementData: {
      outerContainerClassName: styles.reqOuterContainer,
      innerContainerClassName: styles.onboardingInnerContainer,
    },
    childNode: {
      type: nodeType.array,
      childNode,
    },
  };
};

export const getMallModalOptions = (itemList: IMall[]) => {
  if (!itemList) {
    return [];
  }
  const list = itemList?.map((item) => ({
    label: `${item.name} - ${item.address}`,
    value: item.name,
  }));
  return list;
};

export const DealTypeNode = {
  type: nodeType.input,
  elementData: {
    inputType: inputType.selectBox,
    formItemProps: {
      name: FORM_FIELDS.DEAL_TYPE,
      rules: requiredRulesOnChange,
    },
    inputProps: {
      type: ArInputType.text,
      label: FORM_FIELDS.DEAL_TYPE_LABEL,
      required: true,
      options: WIP_LEASING_DEAL_OPTIONS,
    },
  },
};

export const BillingClientNode = {
  type: nodeType.input,
  elementData: {
    inputType: inputType.selectBox,
    formItemProps: {
      name: FORM_FIELDS.BILLING_CLIENT,
      rules: requiredRulesOnChange,
    },
    inputProps: {
      type: ArInputType.text,
      label: FORM_FIELDS.BILLING_CLIENT_LABEL,
      required: true,
      options: BILLING_CLIENT_OPTIONS,
    },
  },
};

export const WIPTypeNode = {
  type: nodeType.input,
  elementData: {
    inputType: inputType.selectBox,
    formItemProps: {
      name: FORM_FIELDS.WIP_TYPE,
      rules: requiredRulesOnChange,
    },
    inputProps: {
      type: ArInputType.text,
      label: FORM_FIELDS.WIP_TYPE_LABEL,
      required: true,
      options: WIP_OPTIONS,
    },
  },
};

export const NumberInputNode = (
  fieldName: string,
  label: string,
  initialValue?: string,
  desc?: string,
  suffix?: ReactNode
) => ({
  type: nodeType.input,
  elementData: {
    inputType: inputType.text,
    formItemProps: {
      name: fieldName,

      initialValue: initialValue,
    },
    inputProps: {
      type: ArInputType.number,
      label: label,
      description: desc,
      varient: varientType.default,

      suffix: suffix,
    },
  },
});

export const TextInputNode: (itemName: string, disabled?: boolean) => ArFormInputNode = (itemName, disabled) => {
  return {
    type: nodeType.input,
    elementData: {
      inputType: inputType.text,
      formItemProps: {
        name: itemName,
        rules: requiredRulesOnChange,
      },
      inputProps: {
        type: ArInputType.text,
        label: itemName,
        varient: varientType.default,
        required: true,
        disabled,
        style: {
          textTransform: "capitalize",
        },
      },
    },
  };
};
