import { ArButton, ArTag, ButtonTypes } from "@anarock/ar-common-react";
import { EditOutlined } from "@ant-design/icons";
import { IWipResponse } from "app/services/wip";
import { getValueOrDash, isNotEqual } from "app/utils/helperFunctions";
import { IWip } from "app/utils/interfaces/index.interface";
import { ShopFeature } from "components/shopDetailComponents/basicImageInfoCard";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React from "react";

import styles from "../../../wip/components/WIP-Summary/index.module.scss";
import { BrokerageProjectionComponent, SummaryCard } from "../../../wip/components/WIP-Summary/partials";
import IConsultingWIPSummaryCardsProps, { ISinglePOCDetailsProps } from "./index.interface";

export const ConsultingWIPSummaryCards = ({
  approvedWIP,
  setPocModal,
  wip,
  openBrokerAgeModal,
  brokerageDetailsChangeCount,
  CommercialDetails,
  ApprovedCommercialDetails,
  commercialDetailsChangeCount,
  setCommercialModal,

  showEditButton,
}: IConsultingWIPSummaryCardsProps) => {
  const billingClient = wip?.data?.wip?.wipMetaData?.billingClient;
  const commercialDetailsArr =
    billingClient === STRING_CONSTANTS.BRAND && CommercialDetails?.[0] && CommercialDetails?.[2]
      ? [CommercialDetails?.[0], CommercialDetails?.[2]]
      : billingClient === STRING_CONSTANTS.LANDLORD && CommercialDetails?.[0] && CommercialDetails?.[1]
      ? [CommercialDetails?.[0], CommercialDetails?.[1]]
      : [...(CommercialDetails || [])];

  const appCommercialDetailsArr =
    billingClient === STRING_CONSTANTS.BRAND && ApprovedCommercialDetails?.[0] && ApprovedCommercialDetails?.[2]
      ? [ApprovedCommercialDetails?.[0], ApprovedCommercialDetails?.[2]]
      : billingClient === STRING_CONSTANTS.LANDLORD && ApprovedCommercialDetails?.[0] && ApprovedCommercialDetails?.[1]
      ? [ApprovedCommercialDetails?.[0], ApprovedCommercialDetails?.[1]]
      : [...(ApprovedCommercialDetails || [])];

  return (
    <>
      <BrandAndLLContacts
        showEditButton={showEditButton}
        openModal={() => setPocModal(true)}
        approvedWIP={approvedWIP}
        wip={wip}
      />

      <SummaryCard
        heading={STRING_CONSTANTS.WIP_SUMMARY_COMMERCIAL}
        data={commercialDetailsArr}
        approvedData={appCommercialDetailsArr}
        isApprovedWIP={approvedWIP ? true : false}
        changeCount={commercialDetailsChangeCount}
        onEdit={() => setCommercialModal(true)}
        showEditButton={showEditButton}
      />

      <BrokerageProjectionComponent
        showEditButton={showEditButton}
        openBrokerAgeModal={openBrokerAgeModal}
        brokerageDetailsChangeCount={brokerageDetailsChangeCount}
        approvedWIP={approvedWIP}
        wip={wip}
      />
    </>
  );
};

export const BrandAndLLContacts = ({
  showEditButton,
  openModal,
  approvedWIP,
  wip,
}: {
  showEditButton: boolean;
  openModal: () => void;
  approvedWIP?: IWip;
  wip?: IWipResponse;
}) => {
  let contactChangeCount = 0;
  const wipLL = wip?.data?.wip?.landlord;
  const approvedLL = approvedWIP?.landlord;

  if (wipLL && wipLL?.length && approvedLL && approvedLL?.length) {
    for (let i = 0; i < wipLL?.length; i++) {
      if (wipLL?.[i]?.landlordId !== approvedLL?.[i]?.landlordId) {
        contactChangeCount++;
      }
      if (JSON.stringify(wipLL?.[i]?.pocIds) !== JSON.stringify(approvedLL?.[i]?.pocIds)) {
        contactChangeCount++;
      }
    }
  }

  const wipBrand = wip?.data?.wip?.brand;
  const approvedBrand = approvedWIP?.brand;

  if (wipBrand && approvedBrand && wipBrand?.poc?.length) {
    if (wipBrand?.brandId !== approvedBrand?.brandId) {
      contactChangeCount++;
    }
    if (JSON.stringify(wipBrand?.pocIds) !== JSON.stringify(approvedBrand?.pocIds)) {
      contactChangeCount++;
    }
  }
  return (
    <div className={styles.summaryCard}>
      <div className={styles.heading}>
        <h4>{STRING_CONSTANTS.ONE_BOTH_CD}</h4>
        {showEditButton && (
          <ArButton onClick={openModal} type={ButtonTypes.Link}>
            <EditOutlined />
            {contactChangeCount ? STRING_CONSTANTS.EDIT_CHANGES : STRING_CONSTANTS.EDIT}
          </ArButton>
        )}
      </div>
      <div className={styles.detailContainer}>
        <div className={styles.proposedDataWrapper}>
          {approvedWIP ? (
            <div className="pb-16">
              <ArTag type="small" varient="extended-primary">
                {STRING_CONSTANTS.PROPOSED_CHANGES} ({contactChangeCount})
              </ArTag>
            </div>
          ) : null}
          <div className={styles.flexAlignment}>
            <div className="pb-16">
              {wip?.data?.wip?.landlord?.length
                ? wip?.data?.wip?.landlord?.map(({ landlordInfo, poc }, idx) => (
                    <>
                      <p className="hero-text-medium pb-16">{`${
                        landlordInfo?.legalEntityName || landlordInfo?.name
                      } (LandLord ${idx + 1})`}</p>
                      {poc?.map((poc, index) => (
                        <SinglePOCDetails
                          index={index}
                          key={index}
                          {...poc}
                          approvedWIPClient={approvedWIP?.landlord?.[idx]?.poc?.[index]}
                        />
                      ))}
                    </>
                  ))
                : "-"}

              {wip?.data?.wip?.brand?.brandInfo && wip?.data?.wip?.brand?.poc?.length ? (
                <>
                  <div className="pb-16"></div>
                  <p className="hero-text-medium pb-16">
                    {getValueOrDash(wip?.data?.wip?.brand?.brandInfo?.brandName)} (
                    {getValueOrDash(wip?.data?.wip?.brand?.brandInfo?.legalEntityName)})
                  </p>
                  {wip?.data?.wip?.brand?.poc?.map((poc, index) => (
                    <SinglePOCDetails
                      index={index}
                      key={index}
                      {...poc}
                      approvedWIPClient={approvedWIP?.brand?.poc?.[index]}
                    />
                  ))}
                </>
              ) : null}
            </div>
          </div>
        </div>
        {approvedWIP && contactChangeCount ? (
          <div className={styles.approvedDataWrapper}>
            <div className="pb-16">
              <ArTag type="small" varient="warning">
                {STRING_CONSTANTS.ORIGINAL_VALUES}
              </ArTag>
            </div>
            <div className={styles.flexAlignment}>
              <div className="pb-16">
                {approvedWIP?.landlord?.length
                  ? approvedWIP?.landlord?.map(({ landlordInfo, poc }, idx) => (
                      <>
                        <p className="hero-text-medium pb-16">{`${
                          landlordInfo?.legalEntityName || landlordInfo?.name
                        } (LandLord ${idx + 1})`}</p>
                        {poc?.map((poc, index) => (
                          <SinglePOCDetails
                            index={index}
                            key={index}
                            {...poc}
                            approvedWIPClient={approvedWIP?.landlord?.[idx]?.poc?.[index]}
                          />
                        ))}
                      </>
                    ))
                  : "-"}
                <div className="pb-16"></div>
                {approvedWIP?.brand?.brandInfo && approvedWIP?.brand?.poc?.length ? (
                  <>
                    <p className="hero-text-medium pb-16">
                      {getValueOrDash(approvedWIP?.brand?.brandInfo?.brandName)} (
                      {getValueOrDash(approvedWIP?.brand?.brandInfo?.legalEntityName)})
                    </p>
                    {approvedWIP?.brand?.poc?.map((poc, index) => (
                      <SinglePOCDetails index={index} key={index} {...poc} />
                    ))}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const SinglePOCDetails = ({ approvedWIPClient, index, name, email, phoneNumber }: ISinglePOCDetailsProps) => {
  return (
    <div className={styles.detailContainer + " " + styles.noBorder + " "}>
      <div className={styles.detailsChild}>
        <ShopFeature
          label={FORM_FIELDS.NAME_LABEL + " " + (index + 1)}
          value={name}
          className={approvedWIPClient ? (isNotEqual(name, approvedWIPClient?.name || "") ? "bold" : "") : ""}
        />
      </div>
      <div className={styles.detailsChild}>
        <ShopFeature
          label={FORM_FIELDS.EMAIL_LABEL + " " + (index + 1)}
          value={email}
          className={approvedWIPClient ? (isNotEqual(email, approvedWIPClient?.email || "") ? "bold" : "") : ""}
        />
      </div>
      <div className={styles.detailsChild}>
        <ShopFeature
          label={FORM_FIELDS.PHONE_NUMBER_LABEL + " " + (index + 1)}
          value={getValueOrDash(phoneNumber?.number) as string}
          className={
            approvedWIPClient
              ? isNotEqual(phoneNumber?.number, approvedWIPClient?.phoneNumber?.number || "")
                ? "bold"
                : ""
              : ""
          }
        />
      </div>
      {/* <div className={styles.detailsChild}>
        <ShopFeature
          label={FORM_FIELDS.DESIGNATION_LABEL + " " + (index + 1)}
          value={designation}
          className={
            approvedWIP
              ? isNotEqual(designation, approvedWIP?.landlord?.poc?.[index]?.designation || "")
                ? "bold"
                : ""
              : ""
          }
        />
      </div> */}
    </div>
  );
};
